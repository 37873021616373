import axios from "axios";

// 创建 axios 实例
const service = axios.create({
    // 服务端api地址
    baseURL: "/",
    timeout: 60 * 1000 // 请求超时时间，60秒, 如果上传大文件需要更长
});

// 接口请求拦截
service.interceptors.request.use(config => {
    // 设置每个请求的Content-Type
    // config.headers['Content-Type'] = 'application/json;charset=utf-8'
  config.headers["Content-Type"] = "application/json;charset=utf-8";
  config.headers["clientid"] = "e5cd7e4891bf95d1d19206ce24a7b32e";
  config.headers["isToken"] = false;
  config.headers["isEncrypted"] = false;

    // 让每个请求携带当前用户token
    const token = localStorage.getItem("access_token");
    if (token) {
        config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
});

// 是否显示未登录提示
let notLoggedMessage = false;

// 接口响应拦截
service.interceptors.response.use((response) => {
    const result = response.data;
    // result.status [ 200正常 500有错误 401未登录 403没有权限访问 ]
    // api报错信息
    if (result.status === 500) {
        message.error(result.message, 1.8);
        // return Promise.reject(result);
    }
    // 鉴权失败: 未登录
    if (result.status === 401) {
        if (!notLoggedMessage) {
            this.$message.error("登录已过期");
            setTimeout(() => {
                this.$route.replace({path: "/login"});
            }, 1500);
        }
        return Promise.reject(result);
    }
    return result;
}, (error) => {
    // 网络请求出错
    const errMsg = ((error.response || {}).data || {}).message || "请求出现错误，请稍后再试";
    this.$message.error("网络请求出错");
    return Promise.reject(error);
});

const installer = {
    vm: {}, install(Vue) {
        Vue.use(VueAxios, service);
    }
};

export {
    installer as VueAxios, service as axios
};
