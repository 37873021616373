<template>
  <div id="app">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: "App"
};
</script>

<style lang="scss">

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
}

button {
  outline: none !important;
}

.ml20 {
  margin-left: 20px;
}

.ml10 {
  margin-left: 10px;
}
</style>
