<template>
  <div style="height: 100%;width: 100%;background-color: rgba(0,0,0,0);">
    <canvas style="height: 100%;width: 100%;" ref="container" id="container"></canvas>
  </div>
</template>

<script>
import axios from "axios";
import {AreaData} from "@/data/areaData";
import {colors} from "@/data/color";

export default {
  name: "mapData",
  data() {
    return {
      colors,
      geoData: null,
      AreaData,
      canvas: null,
      canvasW: null,
      canvasH: null,
      geoCenterX: 0,
      geoCenterY: 0,
      scale: 1,
      offsetX: 0,
      offsetY: 0,
      eventType: "",
      ctx: null,
      mapArr: [],
      isInPath: null,
      mapCode: [
        { "code": 141002,	"name": "尧都区"},
        { "code": 141021,	"name": "曲沃县"},
        { "code": 141022,	"name": "翼城县"},
        { "code": 141023,	"name": "襄汾县"},
        { "code": 141024,	"name": "洪洞县"},
        { "code": 141025,	"name": "古县"},
        { "code": 141026,	"name": "安泽县"},
        { "code": 141027,	"name": "浮山县"},
        { "code": 141028,	"name": "吉县"},
        { "code": 141029,	"name": "乡宁县"},
        { "code": 141030,	"name": "大宁县"},
        { "code": 141031,	"name": "隰县"},
        { "code": 141032,	"name": "永和县"},
        { "code": 141033,	"name": "蒲县"},
        { "code": 141034,	"name": "汾西县"},
        { "code": 141081,	"name": "侯马市"},
        { "code": 141082,	"name": "霍州市"},
      ]
    };
  },
  methods: {
    initCanvas() {
      this.canvas = this.$refs.container;
      this.canvasW = this.canvas.width = window.innerWidth;
      this.canvasH = this.canvas.height = window.innerHeight;
      this.ctx = this.canvas.getContext("2d");
      this.getAreaLatLngData();
    },
    // 分三步，清空画布、绘制地图各子区域、标注城市名称
    drawMap() {
      this.ctx.clearRect(0, 0, this.canvasW, this.canvasH);
      // 画布背景
      this.ctx.fillStyle = "rgba(0,0,0,0)";
      this.ctx.fillRect(0, 0, this.canvasW, this.canvasH);
      this.drawArea();
      this.drawText();
    },

    // 绘制地图各子区域
    drawArea() {
      let _this = this;
      let dataArr = this.geoData.features;
      if (dataArr) {
        let cursorFlag = false;
        for (let i = 0; i < dataArr.length; i++) {
          let centerX = this.canvasW / 2;
          let centerY = this.canvasH / 2;

          //坐标轴循环
          dataArr[i].geometry.coordinates.forEach(area => {
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.translate(centerX, centerY);
            area[0].forEach((elem, index) => {
              let position = this.toScreenPosition(elem[0], elem[1]);
              if (index === 0) {
                this.ctx.moveTo(position.x, position.y);
              } else {
                this.ctx.lineTo(position.x, position.y);
              }
            });
            this.ctx.closePath();
            this.ctx.strokeStyle = "#00cccc";
            this.ctx.lineWidth = 1;
            // 将鼠标悬浮的区域设置为橘黄色
            if (this.ctx.isPointInPath(_this.offsetX, _this.offsetY)) {
              this.isInPath = 1;
              cursorFlag = true;
              this.ctx.fillStyle = "#cf570c";
              if (this.eventType === "click") {
                console.log(dataArr[i].properties);
                window.parent.postMessage({
                  type: "getName",
                  data: {
                    shi: dataArr[i].properties.name,
                    code: dataArr[i].properties.adcode
                  }
                }, "*");
              }
            } else if (dataArr[i].properties.bgColor) {
              //todo 动态定义背景颜色,根据排名由浅到深
              this.ctx.fillStyle = dataArr[i].properties.bgColor;
            } else {
              this.ctx.fillStyle = "#5590c6";
            }
            this.ctx.fill();
            this.ctx.stroke();
            this.ctx.restore();
          });
          this.canvas.style.cursor = "pointer";

          // 动态设置鼠标样式
          if (cursorFlag) {
            this.canvas.style.cursor = "pointer";
          } else {
            this.canvas.style.cursor = "default";
          }
        }
      }
    },

    //获取地图经纬度数据信息
    getAreaLatLngData(decode = "141029") {
      let that = this;
      //todo 渲染区域
      /*let getParams = this.$route.query;
      console.log(getParams);
      if (getParams.xian) {
        areaCodeList.forEach((item) => {
          item.forEach((child) => {
            if (child.fullname == getParams.xian) {
              decode = child.id;
              return;
            }
          });
        });
      } else if (getParams.shi) {
        areaCodeList.forEach((item) => {
          item.forEach((child) => {
            if (child.fullname == getParams.shi) {
              decode = child.id + "_full";
              return;
            }
          });
        });
        this.shi = getParams.shi;
      }*/

      if (decode === '141029') {
        that.geoData = this.AreaData[decode];
        if (this.mapArr.length > 0) {
          let tmpData = this.mapArr;
          tmpData.sort((a, b) => a["隐患总数"] - b["隐患总数"]);
          tmpData.map((a, b) => {
            a.bgColor = this.colors[b];
          });
          tmpData.map(function (item) {
            for (const key in that.geoData.features) {
              if (that.geoData.features[key]["properties"]["name"] == item["shi"] || that.geoData.features[key]["properties"]["name"] == item["xian"]) {
                that.geoData.features[key]["properties"]["bgColor"] = item["bgColor"];
              }
            }
          });
        }

        that.getBoxArea();
        that.drawMap();
      } else {
        console.log('remote');
        // https://map.ruiduobao.com/vectordata/141002.gson
        // https://geo.datav.aliyun.com/areas_v3/bound/${decode}.json
        axios.get(`https://map.ruiduobao.com/vectordata/${decode}.gson`).then(res => {
          that.geoData = res.data;
          if (this.mapArr.length > 0) {
            let tmpData = this.mapArr;
            tmpData.sort((a, b) => a["隐患总数"] - b["隐患总数"]);
            tmpData.map((a, b) => {
              a.bgColor = this.colors[b];
            });
            tmpData.map(function (item) {
              for (const key in that.geoData.features) {
                if (that.geoData.features[key]["properties"]["name"] == item["shi"] || that.geoData.features[key]["properties"]["name"] == item["xian"]) {
                  that.geoData.features[key]["properties"]["bgColor"] = item["bgColor"];
                }
              }
            });
          }

          that.getBoxArea();
          that.drawMap();
        });
      }
    },

    // 标注地图上的城市名称
    drawText() {
      let centerX = this.canvasW / 2;
      let centerY = this.canvasH / 2;
      this.geoData.features.forEach(item => {
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.translate(centerX, centerY); // 将画笔移至画布的中心
        this.ctx.fillStyle = "#fff";
        this.ctx.font = "16px Microsoft YaHei";
        this.ctx.textAlign = "center";
        this.ctx.textBaseLine = "center";
        let x = 0, y = 0;
        //  因不同的geojson文件中中心点属性信息不同，这里需要做兼容性处理
        if (item.properties.cp) {
          x = item.properties.cp[0];
          y = item.properties.cp[1];
        } else if (item.properties.centroid) {
          x = item.properties.centroid[0];
          y = item.properties.centroid[1];
        } else if (item.properties.center) {
          x = item.properties.center[0];
          y = item.properties.center[1];
        }
        let position = this.toScreenPosition(x, y);
        // console.log(item.properties.name, x, y);
        this.ctx.fillText(item.properties.name, position.x, position.y);

        //todo 写出检查数量
        let checkNum = null;
        this.mapArr.forEach(function (e) {
          if (e.shi == item.properties.name || e.xian == item.properties.name || e.xiang == item.properties.name) {
            checkNum = e.隐患总数;
            return;
          }
        });
        if (checkNum) {
          this.ctx.font = "12px Microsoft YaHei";
          this.ctx.fillStyle = "#db7159";
          this.ctx.fillText(checkNum, position.x, position.y + 20);
        }

        this.ctx.restore();
      });
    },
    // 将经纬度坐标转换为屏幕坐标
    toScreenPosition(horizontal, vertical) {
      return {
        x: (horizontal - this.geoCenterX) * this.scale,
        y: (this.geoCenterY - vertical) * this.scale
      };
    },

    // 获取包围盒范围，计算包围盒中心经纬度坐标，计算地图缩放系数
    getBoxArea() {
      let N = -90, S = 90, W = 180, E = -180;
      this.geoData.features.forEach(item => {
        // 将MultiPolygon和Polygon格式的地图处理成统一数据格式
        if (item.geometry.type === "Polygon") {
          item.geometry.coordinates = [item.geometry.coordinates];
        }
        // todo 取四个方向的极值
        item.geometry.coordinates.forEach(area => {
          let areaN = -90, areaS = 90, areaW = 180, areaE = -180;
          area[0].forEach(elem => {
            if (elem[0] < W) {
              W = elem[0];
            }
            if (elem[0] > E) {
              E = elem[0];
            }
            if (elem[1] > N) {
              N = elem[1];
            }
            if (elem[1] < S) {
              S = elem[1];
            }
          });
        });
      });
      // 计算包围盒的宽高
      let width = Math.abs(E - W);
      let height = Math.abs(N - S);
      let wScale = this.canvasW / width;
      let hScale = this.canvasH / height;
      // 计算地图缩放系数
      this.scale = wScale > hScale ? hScale : wScale;
      // 获取包围盒中心经纬度坐标
      this.geoCenterX = (E + W) / 2;
      this.geoCenterY = (N + S) / 2;
    },

    //监听窗口变化,重新加载页面
    listenWindowResize() {
      let _this = this;
      // const cancalDebounce = this.debounce(this.getWindowInfo(), 500);
      // window.addEventListener("resize", function (e) {
      //   let timer = setTimeout(() => {
      //     window.location.reload();
      //     // _this.initCanvas();
      //   }, 500);
      // });
    },
    //鼠标操作事件
    eventListeners() {
      let that = this;
      // 滚轮缩放事件
      this.canvas.addEventListener("mousewheel", function (event) {
        if (event.deltaY > 0) {
          if (that.scale > 10) {
            that.scale -= 10;
          }
        } else {
          that.scale += 10;
        }
        that.eventType = "mousewheel";
        that.drawMap();
      });

      //鼠标按下时间监听
      // this.canvas.addEventListener("mousedown", function (event) {
      //     console.log('down', event)
      // })

      // 鼠标移动事件
      // this.canvas.addEventListener("mousemove", function (event) {
      //   that.offsetX = event.offsetX;
      //   that.offsetY = event.offsetY;
      //   that.eventType = "mousemove";
      //   that.drawMap();
      // });

      // 鼠标点击事件
      that.canvas.addEventListener("click", function (event) {
        that.isInPath = null;
        that.offsetX = event.offsetX;
        that.offsetY = event.offsetY;
        that.eventType = "click";
        that.drawMap();
        if (!that.isInPath == 1) {
          //重新加载所有数据
          parent.postMessage("showAll", "*");
        }
      });
    }
  },
  mounted() {
    let _this = this;
    this.initCanvas();
    this.listenWindowResize();
    this.eventListeners();
    //监听父级传参
    window.addEventListener("message", function (e) {
      if (!e.data.type) {
        if (_this.mapArr.length == 0) {
          _this.mapArr = e.data;
        }
        _this.initCanvas();
      }
    });
  }
};
</script>


<style lang="scss" scoped>
canvas {
  display: block;
  width: 100%;
  height: 100%;
}

#container {
  border: none !important;
}
</style>
