//获取缩放比例
export function getZoom() {
    var ratio = 0,//浏览器当前缩放比
        screen = window.screen,//获取屏幕
        ua = navigator.userAgent.toLowerCase();//判断登陆端是pc还是手机

    if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
    } else if (~ua.indexOf("msie")) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
            ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
    } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth;
    }
    console.log("--------------------", ratio);
    return ratio;
}

//判断是不是市级
export function isInCity(code) {
    let codeArr = [510000, 510100, 510300, 510400, 510500, 510600, 510700, 510800, 510900, 511000, 511100, 511100, 511400, 511500, 511600, 511700, 511800, 511900, 512000, 513200, 513300, 513400];
    if (codeArr.includes(code)) {
        return true;
    }

    return false;
}


// 随机数生成方法
export const randomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function range(start, stop, step = 1) {
    let length = Math.max(Math.ceil((stop - start) / step), 0);
    let range = Array(length);
    for (let i = 0; i < length; i++) {
        range[i] = start + i * step;
    }
    return range;
}
