import Home from "@/views/home";
import Map from "@/views/map";
import Login from "@/views/login";

export default [
    // 首页
    {
        path: "/",
        name: "home",
        meta: {
            title: ""
        },
        component: Home
    },
    {
        path: "/map",
        name: "map",
        meta: {
            title: ""
        },
        component: Map
    },
    {
        path: "/login",
        name: "login",
        meta: {
            title: "系统登录"
        },
        component: Login
    }
];
