<template>
  <div class="main" style="background: linear-gradient(180deg,#1f418d,#121212);background-size: 100%;height:100%;">

    <div class="header" :style="{
      backgroundImage:`url(${topImg})`,
      backgroundSize:'100%',
      backgroundPosition:'center',
      backgroundRepeat: 'no-repeat'
    }">
      <!--<img style="width: 50px;height: 50px;margin-right: 20px;" :src="logo" alt=""/>-->
      <div style="color:#fff;font-weight: bold;font-size:48px;">
        {{ region.name }}党建引领基层消防治理排查系统
      </div>
      <div class="dateBox">
        <span id="time" v-if="year">
          {{ year }}-{{ month }}-{{ date }}&nbsp;&nbsp; {{ hour }}:{{
            minute
          }}:{{ second }}&nbsp;&nbsp; {{ strDate }}
        </span>
        <span v-if="weather_curr">
         <img
           v-if="false"
           style="
          width: 25px;
          height: 16px;
          margin: 0 5px 0 10px;
      "
           :src="weather_icon"
           alt="天气"
         />
          {{ weather_curr }}
        </span>
      </div>
    </div>

    <div class="content">
      <div class="left-container">
        <left :region="region"/>
      </div>
      <!--地图板块-->
      <div class="map-container">
        <map-chart @select="handleSelect"/>
      </div>
      <div class="right-container">
        <right :region="region"/>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/img/logo.png";
import topImg from "@/assets/img/top.png";
import autofit from "autofit.js";
import left from "@/components/left";
import right from "@/components/right";
import axios from "axios";
import MapChart from "@/components/map-chart/index.vue";

export default {
  name: "HomeIndex",
  data: () => {
    return {
      topImg,
      logo,
      datalist: {},
      year: "",
      month: "",
      date: "",
      hour: "",
      minute: "",
      second: "",
      strDate: "",
      weather_curr: "",
      weather_icon: "",
      checkData: [],
      shi: null,
      region: {
        name: "思茅区", adcode: 530802, regionName: "SiMao", level: 3
      },
    };
  },
  components: {
    MapChart,
    left,
    right,
  },
  methods: {
    getCheckData(e) {
      this.checkData = e;
    },
    //获取天气信息
    getWeather: function () {
      let _this = this;
      let wData = localStorage.getItem("weatherData");
      let now = (new Date()).getTime();
      if (!wData) {
        _this.getWeatherApi(now);
      } else {
        let tmp = JSON.parse(wData);
        if (tmp.expire < now) {
          _this.getWeatherApi(now);
        } else {
          let result = tmp.data;
          _this.weather_icon = result?.weather_icon;
          _this.weather_curr = result?.weather_curr;
        }
      }
    },
    getWeatherApi(now) {
      let _this = this;
      axios.get("/wApi", {
        params: {
          "app": "weather.today",
          "weaid": "101270101",
          "appkey": "10003",
          "sign": "b59bc3ef6191eb9f747dd4e83c99f2a4",
          "format": "json",
          "jsoncallback": ""
        }
      }).then(res => {
        let {result} = res.data;
        _this.weather_icon = result?.weather_icon;
        _this.weather_curr = result?.weather_curr;

        localStorage.setItem("weatherData", JSON.stringify({
          data: result,
          expire: now + (30 * 60 * 1000)
        }));
      });
    },
    timeFormate: function (timeStamp) {
      let today;
      let strDate;
      today = new Date();
      let n_day = today.getDay();
      switch (n_day) {
        case 0: {
          strDate = "星期日";
        }
          break;
        case 1: {
          strDate = "星期一";
        }
          break;
        case 2: {
          strDate = "星期二";
        }
          break;
        case 3: {
          strDate = "星期三";
        }
          break;
        case 4: {
          strDate = "星期四";
        }
          break;
        case 5: {
          strDate = "星期五";
        }
          break;
        case 6: {
          strDate = "星期六";
        }
          break;
        case 7: {
          strDate = "星期日";
        }
          break;
      }
      let year = new Date(timeStamp).getFullYear();
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hour =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let minute =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let second =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      this.year = year;
      this.month = month;
      this.date = date;
      this.hour = hour;
      this.minute = minute;
      this.second = second;
      this.strDate = strDate;
    },
    handleSelect(regionVal) {
      this.region = regionVal;
    }
  },
  mounted() {
    //获取get参数
    autofit.init({
      dh: 1080,
      dw: 1920,
      el: "body",
      resize: true,
      ignore: [{
        el: "#container",
        scale: 1.1
      }]
    });

    let _this = this; // 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      //   _this.date = new Date() // 修改数据date
      _this.timeFormate(new Date());
    }, 1000);
    this.getWeather();
    //监听iframe中的点击事件
    window.addEventListener("message", (e) => {
      if (e.data.type && e.data.type == "getName") {
        let {data} = e.data;
        _this.shi = data;
      }
      //todo 加载全部
      if (e.data == "showAll") {
        clearTimeout();
        setTimeout(() => {
          _this.shi = {
            showAll: true
          };
        }, 500);
      }
    }, true);

  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100%;
  display: flex;

  .left-container, .right-container {
    flex: 1;
  }

  .map-container {
    flex: 2;
  }
}
</style>
