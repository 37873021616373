<template>
    <div class="login">
        <el-form ref="loginRef" :model="loginForm" :rules="loginRules" class="login-form">
            <h3 class="title">思茅党建引领基层消防治理排查系统</h3>
            <el-form-item prop="username">
                <el-input v-model="loginForm.username" type="text" size="large" auto-complete="off" placeholder="账号">
                    <template slot="prefix">
                        <i class="el-icon-user"></i>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input v-model="loginForm.password" type="password" size="large" auto-complete="off"
                          placeholder="密码" @keyup.enter="handleLogin">
                    <template slot="prefix">
                        <i class="el-icon-lock"></i>
                    </template>
                </el-input>
            </el-form-item>
            <el-form-item prop="cacheCode">
                <div style="display: flex;align-items: center;">
                    <el-input v-model="loginForm.cacheCode" size="large" type="text" auto-complete="off"
                              placeholder="验证码" @keyup.enter="handleLogin">
                        <template slot="prefix">
                            <i class="el-icon-setting"></i>
                        </template>
                    </el-input>
                    <div style="padding:5px;">
                        <img @click="getCode" :src="cacheCodeUrl" alt="">
                    </div>
                </div>
            </el-form-item>
            <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
            <el-form-item style="width:100%;">
                <el-button :loading="loading" size="large" type="primary" style="width:100%;"
                           @click.prevent="handleLogin">
                    <span v-if="!loading">登 录</span>
                    <span v-else>登 录 中...</span>
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

import {axios} from "@/utils/request";

export default {
    data() {
        return {
            cacheCodeUrl: null,
            loginForm: {
                username: "admin",
                password: "123456",
                rememberMe: true,
                cacheCode: ""
            },
            loginRules: {
                username: [{required: true, trigger: "blur", message: "请填写您的账号"}],
                password: [{required: true, trigger: "blur", message: "请填写您的密码"}],
                cacheCode: [{required: true, trigger: "blur", message: "请填写验证码"}]
            },
            loading: false,
            loginRef: null
        };
    },
    methods: {
        /**
         * 获取验证码
         */
        getCode() {
            let _this = this;
            axios.get("/api/auth/code").then(res => {
                _this.cacheCodeUrl = "data:image/gif;base64," + res.data.img;
                _this.loginForm.uuid = res.data.uuid;
            });
        },
        handleLogin() {
            let _this = this;
            this.$refs.loginRef.validate(async (valid, fields) => {
                if (valid) {
                    _this.loading = true;
                    // 勾选了需要记住密码设置在 localStorage 中设置记住用户名和密码
                    if (_this.loginForm.rememberMe) {
                        localStorage.setItem("username", String(_this.loginForm.username));
                        localStorage.setItem("password", String(_this.loginForm.password));
                        localStorage.setItem("rememberMe", String(_this.loginForm.rememberMe));
                    } else {
                        // 否则移除
                        localStorage.removeItem("tenantId");
                        localStorage.removeItem("username");
                        localStorage.removeItem("password");
                        localStorage.removeItem("rememberMe");
                    }
                    // 调用action的登录方法

                    axios.post("/api/auth/login", {
                        clientId: "e5cd7e4891bf95d1d19206ce24a7b32e",
                        code: _this.loginForm.cacheCode,
                        grantType: "password",
                        password: _this.loginForm.password,
                        rememberMe: false,
                        tenantId: "000000",
                        username: _this.loginForm.username,
                        uuid: _this.loginForm.uuid
                    }).then((res) => {
                        if (res.code == 0) {
                            this.$message.success("登录成功");
                            //缓存信息
                            localStorage.setItem("access_token", res.data.access_token);
                            _this.$router.push({path: "/"});
                        } else {
                            this.$message.error(res.msg);
                            _this.loading = false;
                            _this.getCode();
                        }
                    });
                }
            });
        }
    },
    mounted() {
        const username = localStorage.getItem("username");
        const password = localStorage.getItem("password");
        const rememberMe = localStorage.getItem("rememberMe");
        this.loginForm.username = username || "";
        this.loginForm.password = password || "";
        this.loginForm.rememberMe = rememberMe === "true";

        this.getCode();
    }
};
</script>

<style lang="scss" scoped>
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.title {
    margin: 0px auto 30px auto;
    text-align: center;
    color: #333;
}

.login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 500px;
    padding: 25px 25px 5px 25px;

    .el-input {
        height: 40px;

        input {
            height: 40px;
        }
    }

    .el-input__icon {
        height: 39px;
        width: 14px;
        margin-left: 0px;
    }
}

.el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #333;
    font-family: Arial, serif;
    font-size: 12px;
    letter-spacing: 1px;
}
</style>
