<template>
    <div style="width:100%;margin-top: 47px;"
         v-loading.fullscreen.lock="loading"
    >
        <el-button size="small" @click="goBack" icon="el-icon-back" type="warning">返回</el-button>
        <el-button size="small" v-if="mapLevel ==3" @click="showCheckInfo" icon="el-icon-zoom-in" type="primary">
            检查完成情况
        </el-button>
        <div style="height:5px;"></div>
        <div id="tencent-map" class="map-container"></div>
    </div>
</template>
<script charset="utf-8"
        src="https://map.qq.com/api/gljs?v=1.exp&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&libraries=service">
</script>
<script>
import jgbj from "@/assets/img/jgbj.png";
import community from "@/assets/img/community-new.png";
import gridNew from "@/assets/img/grid-new.png";
import place from "@/assets/img/place.png";
import place1 from "@/assets/img/place-ee6567.png";
import place2 from "@/assets/img/place-e6a23c.png";
import place3 from "@/assets/img/place-91cc75.png";
import gridGreen from "@/assets/img/grid-green.png";
import gridRed from "@/assets/img/grid-red.png";
import communityGreen from "@/assets/img/community-green.png";
import communityRed from "@/assets/img/community-red.png";
import {axios} from "@/utils/request";
import {randomInt} from "@/utils";
import {EventBus} from "@/utils/eventBus";

export default {
    name: "TencentMap",
    props: {
        districtCode: {
            type: String | Number,
            required: true
        }
    },
    data() {
        return {
            jgbj,
            gridNew,
            gridGreen,
            gridRed,
            communityGreen,
            communityRed,
            place1,
            place2,
            place3,
            community,
            map: null,
            points: null,
            lightEl: null,
            markerCluster: null,
            DistrictOverlay: null,
            tmpZoom: 0,
            center: new TMap.LatLng(22.819609, 100.986569),
            isUpdate: false,
            pointList: [],
            zoom: null,
            infoWindow: [],
            mapLevel: 1,
            communityList: [],
            gridList: [],
            placeList: [],
            checkLevelArr: [{}, {
                text: "不放心",
                color: "#ee6567"
            }, {
                text: "稍微放心",
                color: "#e6a23c"
            }, {
                text: "放心",
                color: "#91cc75"
            }],
            commonId: 0,
            loading: false
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        showCheckInfo() {
            this.map.destroy();
            this.getPointList(4);
            this.createMap();
            this.createPoint();

            // 监听地图缩放事件
            this.map.addListener("zoom_changed", (e) => {
                let tmpZoom1 = parseInt(this.map.getZoom());
            });

            // 监听地图点击事件
            this.map.addListener("click", (e) => {
            });

            this.map.addListener("hover", (e) => {
            });
        },
        goBack() {
            this.pointList = [];
            if (this.mapLevel == 1) {
                //返回到初始图层
                this.$emit("goBack");
            } else {
                this.mapLevel -= 1;
                window.localStorage.setItem("map_level", this.mapLevel);
                if (this.mapLevel == 2) window.localStorage.removeItem("grid_id");
                if (this.mapLevel == 1) window.localStorage.removeItem("community_id");
                this.mapLevel == 1 ? this.zoom = 13.8 : this.zoom = 16.4;
                this.map.destroy();
                this.init();
            }
            EventBus.$emit("community_id_changed", 0);
        },
        init() {
            this.loading = true;
            let _this = this;
            if (_this.mapLevel == 2) {
                _this.commonId = window.localStorage.getItem("community_id");
            } else if (_this.mapLevel == 3) {
                _this.commonId = window.localStorage.getItem("grid_id");
            }
            axios.get("/api/api/smapi/getCommunityList", {
                params: {
                    districtCode: this.districtCode,
                    mapLevel: this.mapLevel,
                    commonId: _this.commonId
                }
            }).then(({data}) => {
                _this.loading = false;
                if (_this.mapLevel == 1) _this.communityList = data;
                if (_this.mapLevel == 2) _this.gridList = data;
                if (_this.mapLevel == 3) _this.placeList = data;
                this.getPointList(_this.mapLevel);
                this.createMap();
                // this.districtorOverlay(); // 区划区域展示
                // this.showInfoWindow(); // 展示信息窗口
                this.createPoint();

                // 监听地图缩放事件
                this.map.addListener("zoom_changed", (e) => {
                    let tmpZoom1 = parseInt(this.map.getZoom());
                });

                // 监听地图点击事件
                this.map.addListener("click", (e) => {
                });

                this.map.addListener("hover", (e) => {
                });
            });
        },
        //创建地图图层
        createMap(type = 1) {
            var drawContainer = document.getElementById("tencent-map");
            let baseMap;
            if (type == 1) {
                baseMap = {
                    type: "vector",
                    features: ["base", "building3d"] // 隐藏矢量文字
                };
            } else {
                baseMap = {};
            }
            this.map = new TMap.Map("tencent-map", {
                zoom: this.zoom || 13.8, // 地图初始缩放
                pitch: 30,
                center: this.center,
                baseMap
            });

            // this.map.setMapStyleId("style4"); // 设置地图样式
            // 展示地图缩放等级的数字
            var control = this.map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
            control.setNumVisible(true);
        },
        createPoint() {
            let _this = this;
            this.markerCluster && this.markerCluster.destroy();
            //自定义点样式
            const pointsStyle = {
                styleRed: new TMap.MarkerStyle({
                    width: 34,
                    height: 42,
                    color: "#ff6542",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: jgbj
                }),
                styleGreen: new TMap.MarkerStyle({
                    width: 100,
                    height: 100,
                    color: "#29c20e",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.jgbj
                }),
                sqLevel: new TMap.MarkerStyle({
                    width: 80,
                    height: 80,
                    color: "#1296db",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.community
                }),
                communityGreen: new TMap.MarkerStyle({
                    width: 80,
                    height: 80,
                    color: "#a3d48c",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.communityGreen
                }),
                communityRed: new TMap.MarkerStyle({
                    width: 80,
                    height: 80,
                    color: "#ee6567",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.communityRed
                }),
                gridLevel: new TMap.MarkerStyle({
                    width: 80,
                    height: 80,
                    color: "#1296db",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.gridNew
                }),
                gridGreen: new TMap.MarkerStyle({
                    width: 80,
                    height: 80,
                    color: "#a3d48c",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.gridGreen
                }),
                gridRed: new TMap.MarkerStyle({
                    width: 80,
                    height: 80,
                    color: "#ee6567",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.gridRed
                }),
                placeLevel1: new TMap.MarkerStyle({
                    width: 50,
                    height: 50,
                    color: "#ee6567",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.place1
                }),
                placeLevel2: new TMap.MarkerStyle({
                    width: 50,
                    height: 50,
                    color: "#e6a23c",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.place2
                }),
                placeLevel3: new TMap.MarkerStyle({
                    width: 50,
                    height: 50,
                    color: "#91cc75",
                    direction: "bottom",
                    anchor: {
                        x: 17,
                        y: 21
                    },
                    src: this.place3
                })
            };
            //初始化marker
            var marker = new TMap.MultiMarker({
                id: "marker-layer", //图层id
                map: this.map,
                // 定义标点样式
                styles: pointsStyle,
                geometries: this.pointList
            });

            //地图定点点击
            marker.on("click", (e) => {
                if (e.geometry.levelType == "community") { //点击社区
                    //渲染网格
                    _this.zoom = 14;
                    window.localStorage.setItem("community_id", e.geometry.properties.item.communityId);
                    window.localStorage.setItem("map_level", 2);
                    EventBus.$emit("community_id_changed", e.geometry.properties.item.communityId);
                    _this.gridList = e.geometry.properties.item.gridList;
                    this.mapLevel = 2;
                    _this.getPointList(2);
                    this.map.destroy();
                    _this.init();
                    //向父级传递参数
                } else if (e.geometry.levelType == "grid") {
                    _this.zoom = 17.7;
                    //点击网格，显示具体检查主体
                    // _this.commonId = e.geometry.properties.item.gridId;
                    window.localStorage.setItem("grid_id", e.geometry.properties.item.gridId);
                    window.localStorage.setItem("map_level", 3);
                    EventBus.$emit("community_id_changed", e.geometry.properties.item.communityId);
                    _this.placeList = e.geometry.properties.item.placeList;
                    this.mapLevel = 3;
                    _this.getPointList(3);
                    this.map.destroy();
                    this.init();
                }
            });

            //地图定点展示
            marker.on("hover", (e) => {
                let infoCenter = new TMap.LatLng(e.latLng.lat, e.latLng.lng);
                if (e.geometry) {
                    let divContent = "";
                    switch (e.geometry.levelType) {
                        case "community":
                            divContent = "<div style='height:auto;text-align: left;line-height:28px'>"
                                + `<p style='margin-top:10px;'><h3>${e.geometry.content}</h3>`
                                + "<p>消防挂钩联系人：" + e.geometry.properties.item.fireLinkMan + "</p>"
                                + "<p>消防挂钩联系人电话：" + e.geometry.properties.item.fireLinkPhone + "</p>"
                                + "<p>社区负责人：" + e.geometry.properties.item.principal + "</p>"
                                + "<p>社区负责人电话：" + e.geometry.properties.item.principalPhone + "</p>"
                                + "<p>下属网格总数：" + e.geometry.properties.item.totalGrid + "</p>";
                            "</div>";
                            break;
                        case "grid":
                            divContent = "<div style='height:auto;text-align: left;line-height:28px'>"
                                + "<p style='margin-top:10px;'><h3>" + e.geometry.content + "</h3>"
                                + "<p>所属社区：" + e.geometry.properties.item.communityName + "</p>";
                            "</div>";
                            break;
                        case "place":
                            let lastCheck = e.geometry.properties.item.lastCheckLog;
                            let checkResult = lastCheck ? lastCheck.checkResult : null;
                            let checkTime = lastCheck ? lastCheck?.createTime.split(" ")[0] : " -- ";
                            divContent = "<div style='height:auto;text-align: left;line-height:28px'>"
                                + "<div style='text-align: center'><img style='height:100px;' src='" + e.geometry.properties.item.companyPic + "' /></div>"
                                + "<p style='margin-top:10px;'><h3>" + e.geometry.content + "</h3>"
                                + "<p>地址：" + e.geometry.properties.item.address + "</p>"
                                + "<p>负责人：" + e.geometry.properties.item.contactPerson + "</p>"
                                + "<p>负责人电话：" + e.geometry.properties.item.contactPhone + "</p>"
                                + "<p>系统负责人：" + e.geometry.properties.item.sysManage + "</p>"
                                + "<p>系统负责人电话：" + e.geometry.properties.item.sysManagePhone + "</p>"
                                + "<p>检查等级：<span style='color:#fff;padding:5px;background:" + _this.checkLevelArr[e.geometry.properties.item.checkLevel].color + "'>" + _this.checkLevelArr[e.geometry.properties.item.checkLevel].text + "</span></p>"
                                + "<p>最后一次检查时间：" + checkTime + "</p>"
                                + `<p>检查结果：<span style='color:${!checkResult ? "#e6a23c" : (checkResult == 2 ? "#ee6567" : "#91cc75")};'>${checkResult ? (checkResult == 1 ? "合格" : "不合格") : "未检查"}</span></p>`;
                            "</div>";
                            break;
                    }
                    _this.infoWindow.push(new TMap.InfoWindow({
                        content: divContent, //在content中直接传入html代码, //设置信息窗口的文本内容
                        position: infoCenter,//设置显示信息窗口的坐标
                        map: _this.map, //指定信息窗体显示在哪张地图上
                        offset: {x: 0, y: -32} //调整信息窗相对position偏移量，以使其显示在Marker的上方
                    }));
                } else {
                    // infoWindow.destroy();//初始关闭信息窗体
                    for (const eKey in _this.infoWindow) {
                        _this.infoWindow[eKey].destroy();
                    }
                }
            });
        },
        //获取定位数据
        getPointList(type) {
            let _this = this;
            //todo 获取地图标记层数据
            switch (type) {
                case 1://社区层
                    let tmpArr = ["communityRed", "communityGreen"];
                    _this.communityList.map(function (item) {
                        let tmp = item.locationPoint.split(",");
                        _this.pointList.push({
                            position: new TMap.LatLng(parseFloat(tmp[0]), parseFloat(tmp[1])), // 点标记坐标位置
                            styleId: item.notCheckPlaces == 0 ? "communityGreen" : "communityRed",
                            content: `${item.communityName}（${item.totalGrid}）`,
                            levelType: "community",
                            properties: {//自定义属性
                                item: item,
                                title: item.communityName
                            }
                        });
                    });
                    break;
                case 2://网格层
                    let styleArr = ["gridGreen", "gridRed"];
                    _this.pointList = [];
                    _this.gridList.map((item, index) => {
                        let tmp = item.gridPoint.split(",");
                        _this.pointList.push({
                            position: new TMap.LatLng(parseFloat(tmp[0]), parseFloat(tmp[1])), // 点标记坐标位置
                            styleId: item.totalPlace == 0 ? "gridGreen" : "gridRed",
                            content: `${item.gridName}（${item.totalPlace}）`,
                            levelType: "grid",
                            properties: {//自定义属性
                                item: item
                            }
                        });
                    });
                    break;
                case 3: //场所层
                    _this.pointList = [];
                    _this.placeList.map((item, index) => {
                        let tmp = item.location.coordinates;
                        _this.pointList.push({
                            position: new TMap.LatLng(tmp[1], tmp[0]), // 点标记坐标位置
                            styleId: "placeLevel" + item.checkLevel,
                            content: item.placeName,
                            levelType: "place",
                            properties: {//自定义属性
                                item: item
                            }
                        });
                    });
                    break;
                case 4: //场所层 -- 检查情况展示
                    _this.pointList = [];
                    _this.placeList.map((item, index) => {
                        let tmp = item.location.coordinates;
                        _this.pointList.push({
                            position: new TMap.LatLng(tmp[1], tmp[0]), // 点标记坐标位置
                            styleId: item.lastCheckLog ? "placeLevel3" : "placeLevel1",
                            content: item.placeName,
                            levelType: "place",
                            properties: {//自定义属性
                                item: item
                            }
                        });
                    });
                    break;
            }
        }
    }
};
</script>
<style>
.map-container {
    width: 100%;
    height: 880px;
}
</style>
