<template>
    <!--列表统计-->
    <div class="rightBox" style="overflow-y: hidden">
        <div class="weihutj" style="height: 400px">
            <dv-border-box-13 style="height: 100%">
                <div style="height: 100%; overflow: hidden">
                    <div class="pfTitle">
                        <div class="flex">
                            <img :src="gzt" alt=""/>
                            <span class="titleText">人员检查情况</span>
                        </div>
                    </div>
                    <div
                        v-if="personWorkData.length > 0"
                        style="color:#fff;padding:10px;line-height:28px;overflow-y: scroll;display: flex;justify-content: space-between;height: 99%;">
                        <div style="display: flex;flex-direction: column;align-items: center;flex-shrink: 0">
                            <div>排序</div>
                            <div v-for="(item,index) in personWorkData">{{ index + 1 }}</div>
                        </div>

                        <div style="display: flex;flex-direction: column;align-items: center;width:200px;">
                            <div>姓名</div>
                            <div v-for="(item,index) in personWorkData">{{ item["姓名"] }}</div>
                        </div>

                        <div
                            style="display: flex;width:70%;flex-direction: column;align-items: center;white-space: nowrap;">
                            <div>地区</div>
                            <div v-for="(item,index) in personWorkData">{{
                                    `${item.县}  ${item.乡 ? "-" + item.乡 : ""}`
                                }}
                            </div>
                        </div>

                        <div
                            style="display: flex;width:70%;flex-direction: column;align-items: center;white-space: nowrap;">
                            <div>检查家次</div>
                            <div v-for="(item,index) in personWorkData">{{ item.检查家次 }}</div>
                        </div>
                    </div>
                    <div v-else
                         style="height: 80%;display: flex;flex-direction: column;justify-content: center;align-items: center">
                        <dv-loading style="color:#999;">Loading...</dv-loading>
                    </div>
                </div>
            </dv-border-box-13>
        </div>

        <div class="guzhangtj" style="height:500px;">
            <dv-border-box-13 style="height: 100%;">
                <div style="height: 100%;">
                    <div v-if="!gridId" style="position: relative;height:100%;overflow-y: hidden">
                        <div class="pfTitle">
                            <div class="flex">
                                <img :src="gzt" alt=""/>
                                <span class="titleText">{{
                                        region.level === 2 ? "区/县" : "街道/乡镇"
                                    }}隐患发现及整改</span>
                            </div>
                        </div>
                        <div class="pfcontent"
                             v-if="troubles.length > 0"
                             style="color:#fff;padding:10px;line-height:28px;overflow-y: scroll;height: 90%;display: flex;justify-content: space-between">
                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div>
                                    <div>排序</div>
                                    <br></div>
                                <div v-for="(dItem,dIndex) in troubles">{{ dIndex + 1 }}</div>
                            </div>

                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div>
                                    <div>地区</div>
                                    <div><br></div>
                                </div>
                                <div v-for="(dItem,dIndex) in troubles">{{ dItem.districtName }}</div>
                            </div>

                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div style="display: flex;flex-direction: column;align-items: center">
                                    <div>检查</div>
                                    <div style="font-size: 12px;">（家/次）</div>
                                </div>
                                <div v-for="(dItem,dIndex) in troubles">{{ dItem.checkCount }}</div>
                            </div>

                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div style="display: flex;flex-direction: column;align-items: center">
                                    <div>
                                        发现隐患
                                    </div>
                                    <div style="font-size: 12px">（条）</div>
                                </div>
                                <div v-for="(dItem,dIndex) in troubles">{{ dItem.troubleCount }}</div>
                            </div>

                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div>
                                    <div>已整改</div>
                                    <br>
                                </div>
                                <div v-for="(dItem,dIndex) in troubles">
                                    {{ dItem.duplicateCheckCount + dItem.immediateRectificationCount }}
                                </div>
                            </div>
                        </div>
                        <div v-else
                             style="height: 80%;display: flex;flex-direction: column;justify-content: center;align-items: center">
                            <dv-loading style="color:#999;">Loading...</dv-loading>
                        </div>
                    </div>

                    <div v-else style="position: relative;height:100%;overflow-y: hidden">
                        <div class="pfTitle">
                            <div class="flex">
                                <img :src="gzt" alt=""/>
                                <span class="titleText">场所检查情况</span>
                            </div>
                        </div>
                        <div class="pfcontent"
                             v-if="troubles.length > 0"
                             style="color:#fff;padding:10px;line-height:28px;overflow-y: scroll;height: 90%;display: flex;justify-content: space-between">
                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div>
                                    <div>排序</div>
                                    <br></div>
                                <div v-for="(dItem,dIndex) in troubles">{{ dIndex + 1 }}</div>
                            </div>

                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div>
                                    <div>场所名称</div>
                                    <div><br></div>
                                </div>
                                <div v-for="(dItem,dIndex) in troubles">{{ dItem.placeName }}</div>
                            </div>

                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div style="display: flex;flex-direction: column;align-items: center">
                                    <div>检查等级</div>
                                    <div><br></div>
                                </div>
                                <div v-for="(dItem,dIndex) in troubles">
                                    <el-tag effect="dark" v-if="dItem.checkLevel == 1" type="danger"
                                            size="small">不放心
                                    </el-tag>
                                    <el-tag effect="dark" v-if="dItem.checkLevel == 3" type="success"
                                            size="small">放心
                                    </el-tag>
                                    <el-tag effect="dark" v-if="dItem.checkLevel == 2" type="warning"
                                            size="small">稍微放心
                                    </el-tag>
                                </div>
                            </div>

                            <div style="display: flex;flex-direction: column;align-items: center">
                                <div style="display: flex;flex-direction: column;align-items: center">
                                    <div>
                                        近期是否检查
                                    </div>
                                    <div><br></div>
                                </div>
                                <div v-for="(dItem,dIndex) in troubles">
                                    <el-tag v-if="dItem.isChecked" size="small" type="success" effect="dark">
                                        已检查
                                    </el-tag>
                                    <el-tag v-if="!dItem.isChecked" size="small" type="danger" effect="dark">
                                        未检查
                                    </el-tag>
                                </div>
                            </div>
                        </div>
                        <div v-else
                             style="height: 80%;display: flex;flex-direction: column;justify-content: center;align-items: center">
                            <dv-loading style="color:#999;">Loading...</dv-loading>
                        </div>
                    </div>
                </div>
            </dv-border-box-13>
        </div>
    </div>
</template>

<script>
import aqpf from "@/assets/img/aqpf.png";
import aqdj from "@/assets/img/aqdj.png";
import jzaqpf from "@/assets/img/jzaqpf.png";
import aqyh from "@/assets/img/aqyh.png";
import aqyhtop100 from "@/assets/img/aqyhtop100.png";
import xfjs from "@/assets/img/xfjs.png";
import ldbj from "@/assets/img/ldbj.png";
import jgbj from "@/assets/img/jgbj.png";
import rcxj from "@/assets/img/rcxj.png";
import whtj from "@/assets/img/whtj.png";
import gzt from "@/assets/img/gzt.png";
import hj from "@/assets/img/hj.png";
import zfjg from "@/assets/img/zfjg.png";
import zfjgtj from "@/assets/img/zfjgtj.png";
import {axios} from "@/utils/request";
import {randomInt} from "@/utils";
import testData from "@/data/testData.json";
import {EventBus} from "@/utils/eventBus";

export default {
    name: "RightWrapper",
    data: () => {
        return {
            testData,
            aqpf,
            aqdj,
            jzaqpf,
            aqyh,
            aqyhtop100,
            xfjs,
            ldbj,
            jgbj,
            rcxj,
            whtj,
            gzt,
            hj, zfjg, zfjgtj,
            datalist: {},
            personWorkData: [],
            troubles: [],
            shiParam: null,
            xian: null,
            gridId:null
        };
    },
    props: {
        region: {
            type: Object,
            default: () => ({})
        }
    },
    watch: {
        region: {
            handler: function () {
                this.getTroubleStats();
            }
        }
    },
    methods: {
        getUserList() {
            let rows;
            let grid = window.localStorage.getItem("grid_id");
            if (grid) {
                rows = [{
                    "姓名": "马锐",
                    "电话": "13987919491",
                    "检查家次": 0,
                    "市": "普洱市",
                    "县": "思茅区",
                    "乡": "茶马古城"
                }, {
                    "姓名": "高改明",
                    "电话": "13987919491",
                    "检查家次": 0,
                    "市": "普洱市",
                    "县": "思茅区",
                    "乡": "茶马古城"
                }, {
                    "姓名": "李志伟",
                    "电话": "13987919491",
                    "检查家次": 0,
                    "市": "普洱市",
                    "县": "思茅区",
                    "乡": "茶马古城"
                }, {
                    "姓名": "白春华",
                    "电话": "13987919491",
                    "检查家次": 0,
                    "市": "普洱市",
                    "县": "思茅区",
                    "乡": "茶马古城"
                }, {
                    "姓名": "陈涛",
                    "电话": "13987919491",
                    "检查家次": 0,
                    "市": "普洱市",
                    "县": "思茅区",
                    "乡": "茶马古城"
                }];

                rows.forEach((row) => row["检查家次"] = randomInt(50, 110));
            } else {
                rows = [
                    {
                        "姓名": "马锐",
                        "电话": "13987919491",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "茶马古城"
                    }, {
                        "姓名": "高改明",
                        "电话": "13987919491",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "茶马古城"
                    }, {
                        "姓名": "李志伟",
                        "电话": "13987919491",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "茶马古城"
                    }, {
                        "姓名": "白春华",
                        "电话": "13987919491",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "茶马古城"
                    }, {
                        "姓名": "陈涛",
                        "电话": "13987919491",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "茶马古城"
                    },
                    {
                        "姓名": "罕德伟",
                        "电话": "18087919816",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅街道"
                    },
                    {
                        "姓名": "兰云云",
                        "电话": "18788411107",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅街道"
                    },
                    {
                        "姓名": "者丽萍",
                        "电话": "18388285473",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "南屏镇"
                    },
                    {
                        "姓名": "自美英",
                        "电话": "15911299205",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "倚象镇"
                    },
                    {
                        "姓名": "李丽",
                        "电话": "13578170767",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅港镇"
                    },
                    {
                        "姓名": "李开明",
                        "电话": "13769932183",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "六顺镇"
                    },
                    {
                        "姓名": "李加福",
                        "电话": "15925250247",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅街道"
                    },
                    {
                        "姓名": "陈珊",
                        "电话": "15154886841",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "六顺镇"
                    },
                    {
                        "姓名": "毛映鸥",
                        "电话": "13769969689",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅街道"
                    },
                    {
                        "姓名": "方浩力",
                        "电话": "18187917886",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅港镇"
                    },
                    {
                        "姓名": "丁杰",
                        "电话": "18987910739",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅港镇"
                    },
                    {
                        "姓名": "卢铁刚",
                        "电话": "13987919491",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅街道"
                    },
                    {
                        "姓名": "伍桁宏",
                        "电话": "18087735199",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "南屏镇"
                    },
                    {
                        "姓名": "杨成",
                        "电话": "15912952538",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅街道"
                    },
                    {
                        "姓名": "王石江",
                        "电话": "15758383317",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "南屏镇"
                    },
                    {
                        "姓名": "李少龙",
                        "电话": "17787990521",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅街道"
                    },
                    {
                        "姓名": "雷明舒",
                        "电话": "13577911792",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "南屏镇"
                    },
                    {
                        "姓名": "胡异新",
                        "电话": "14787976917",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅港镇"
                    },
                    {
                        "姓名": "高明虎",
                        "电话": "18108796007",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "思茅港镇"
                    },
                    {
                        "姓名": "杨建荣",
                        "电话": "15925275065",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "六顺镇"
                    },
                    {
                        "姓名": "杨家金",
                        "电话": "15911278347",
                        "检查家次": 0,
                        "市": "普洱市",
                        "县": "思茅区",
                        "乡": "六顺镇"
                    }
                ];
                rows.forEach((row) => row["检查家次"] = randomInt(50, 110));
            }
            return rows;
        },
        apiInit() {
            this.getPersonWorkInfo();
            this.getTroubleStats();
        },
        //人员绩效排名
        getPersonWorkInfo() {
            let _this = this;
            let tmp = false;
            let rows = this.getUserList();
            _this.personWorkData = rows;
        },

        //隐患及整改排名
        getTroubleStats() {
            let _this = this;
            let gridId = window.localStorage.getItem("grid_id");
            _this.gridId = gridId;
            if (gridId) {
                axios.get("/api/api/smapi/getAllPlace", {
                    params: {
                        gridId: gridId
                    }
                }).then(({data}) => {
                    this.troubles = data;
                });
            } else {
                /*let sortArr = "成都市、自贡市、攀枝花市、泸州市、德阳市、绵阳市、广元市、遂宁市、内江市、乐山市、南充市、眉山市、宜宾市、广安市、达州市、雅安市、巴中市、资阳市、阿坝藏族羌族自治州、甘孜藏族自治州、凉山彝族自治州";
           sortArr = sortArr.split("、");*/
                axios.get("/api/api/datav/risk/stats", {
                    params: {
                        districtCode: this.region.adcode
                    }
                }).then(({data}) => {
                    _this.troubles = data;
                });
            }
        },
        handleStorageChange() {
            this.apiInit();
        }
    },
    mounted() {
        //总线监听
        EventBus.$on("community_id_changed", this.handleStorageChange);
        let getParams = this.$route.query;
        this.shiParam = getParams.shi;
        this.xian = getParams.xian;
        this.apiInit();
    },
    beforeDestroy() {
        // 移除监听
        EventBus.$off("community_id_changed", this.handleStorageChange);
    }
};
</script>

<style lang="scss" scoped>
.titleText {
    margin-left: 10px;
    font-size: 18px;
    font-weight: bold;
}
</style>
